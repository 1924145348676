import React from 'react'
import { Button, Modal } from 'antd';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
export default function PopupConfirm({ show, handleClose, title, description, icon, loading, onSave }) {

    const { t } = useTranslation()

    return (
        <div>
            <Modal
                // title="Basic Modal"
                open={show}
                onOk={onSave}
                centered
                closable={false}
                transitionName={false}
                okText={loading ? <Spinner size='sm' /> : t('Ok')}
                cancelText={t('Cancel')}
                onCancel={handleClose}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    lineHeight: 2.5,
                    paddingTop: 50,
                    paddingBottom: 50,
                    fontSize: 18
                }}>
                    <span> {icon}</span>
                    <span style={{ fontSize: 20 }}>{title}</span>
                    <span>{description}</span>
                </div>
            </Modal>
        </div>
    )
}
