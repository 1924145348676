

export const VERSION = "V 1.0.0"


export const convertNotiStatus = (status) => {
    switch (status) {
        case "waiting":
            return "default";
        case "canceled":
            return "red";
        default:
            return "success";
    }
};