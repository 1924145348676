import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import axios from 'axios';
const NotificationConext = createContext()

export default function NotificationProvider({ children }) {
    const [selectEditData, setSelectEditData] = useState()
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false)

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "notifications/list"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    // Add other headers if needed
                },
            });

            setNotifications(response?.data?.results);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
        }
    };

    
    // console.log("========notifications",notifications)

    return (
        <NotificationConext.Provider value={{ notifications, setNotifications,selectEditData,setSelectEditData,loading,fetchData }} >
            {children}
        </NotificationConext.Provider>
    )
}

export function useNotification() {
    return useContext(NotificationConext)
}
