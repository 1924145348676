import './App.css';
import React, { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import EmployeeProvider from './context/EmployeeContext';
import NotificationProvider from './context/NotificationContext';
import Router from './route/Router';
import ErrorModal from './pages/redirect/ErrorModal';
import { I18nextProvider } from "react-i18next";
import i18n from './language/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const [openModal, setOpenModal] = useState(true)

  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const tokenString = getCookie('user');

  // Decode the URL-encoded string
  const decodedTokenString = decodeURIComponent(tokenString);

  const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

  useEffect(() => {
    if (!tokenData) {
      setOpenModal(true)
      localStorage.removeItem('selectedStatus');
      localStorage.removeItem('MobileNoti-PageNumber');
    } else {
      if (tokenData?.token) {
        // setAuthToken(tokenData?.token)
        setOpenModal(false)
      }
    }
  }, [tokenData]);

  return (
    <>
      <ConfigProvider
        theme={{ token: { fontFamily: "Noto Sans Lao" } }}>
        <EmployeeProvider>
          <NotificationProvider>
            <I18nextProvider i18n={i18n}>
              <Router />
            </I18nextProvider>
          </NotificationProvider>
        </EmployeeProvider>
      </ConfigProvider>
      <ErrorModal
        show={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default App;
