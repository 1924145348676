import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, Navigate, useLocation } from "react-router-dom"
import { Layout, Menu, theme, Dropdown, Button, Space } from "antd";
import { MenuFoldOutlined, HomeOutlined, MenuUnfoldOutlined, NotificationOutlined, DownOutlined } from "@ant-design/icons";
// import { isUserLogin } from '../const';
import MainLayout from '../components/MainLayout';
import useWindowDimensions from '../constants/useWindowDimensions';
import SideBar from '../components/SideBar';
import { VERSION } from '../constants';
import { useTranslation } from 'react-i18next';

export default function PrivateRoute() {
    const { width } = useWindowDimensions();
    // const isAuthenticated = isUserLogin()
    // const navigate = useNavigate()
    // if (!isAuthenticated) {
    const navigate = useNavigate();
    const [language, setLanguage] = useState(localStorage.getItem('locale') || "LA");
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const _changeLanguage = (locale) => {
        const { pathname, search } = location;
        const newLocation = `${pathname}${search}`;
        // Save the selected locale to localStorage
        localStorage.setItem('locale', locale);
        navigate(newLocation, { state: { locale } });
        i18n.changeLanguage(locale);
        setLanguage(locale);
    };

    useEffect(() => {
        localStorage.getItem('locale')
    }, [language])

    const items = [
        {
            img: "image_url_for_lao", // Add the URL for the image for LAO
            label:
                <div className=' flex' onClick={() => _changeLanguage('LA')}>
                    <img src="/images/Laos.png" alt="Lao flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                    <span className=' font-bold text-[#076638]'>LAO</span>
                </div>,
            key: '0'
        },
        {
            img: "image_url_for_english", // Add the URL for the image for English
            label:
                <div className=' flex' onClick={() => _changeLanguage('EN')}>
                    <img
                        src="/images/english.png"
                        alt="English flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                    <span className=' font-bold text-[#076638]'>ENGLISH</span>
                </div>,
            key: '1'
        },
    ];

    return (

        <div>
            {
                width > 700 ?
                    <MainLayout>
                        <Outlet />
                    </MainLayout> :
                    <>
                        <div>
                            <div style={{
                                width: "100%",
                                height: 60,
                                background: '#111827',
                                position: "fixed",
                                zIndex: 1000,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <SideBar />
                                {/* <span style={{ color: "#FFFFFF" }}>PSVG NOTIFICATION</span> */}
                                <div>
                                    <Dropdown
                                        className=' max-sm:w-[50%]'
                                        menu={{
                                            items,
                                        }}
                                        trigger={['click']}
                                    >
                                        <Button
                                            // style={{ backgroundColor: "#FFCC08" }}
                                            style={{ color: "white" }}
                                            type=""
                                            className='cursor-pointer hover:bg-none lg:ml-8  text-green-900 font-bold '>
                                            <Space>
                                                {language === "EN" ?
                                                    <img
                                                        src="/images/english.png"
                                                        alt="English flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                                                    :
                                                    <img
                                                        src="/images/Laos.png"
                                                        alt="Lao flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                                                }
                                                {language}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                            <div style={{ paddingTop: 60 }}>
                                <Outlet />
                            </div>
                        </div>
                    </>
            }

        </div>
    );

    // return <Navigate to={'/login'} />
}
// }
