import React, { useState, useTransition } from 'react';
import { Button, Result } from 'antd';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../api';
import { useTranslation } from 'react-i18next';

export default function ErrorModal({ show, handleClose }) {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const setCookie = (name, value, days) => {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${expirationDate.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    };

    const handleClick = () => {
        const tokenData = {
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImhydXNlciIsInVzZXJJZCI6IlBTVi0wMC0xNzQxMzciLCJpYXQiOjE3MDE3MDU2MTQsImV4cCI6MTcwMTczNDQxNH0.Z-xCnI65yNCWe0kJ13jD7WoPAuUV2MnKHajnQAWUKr8",
            id: "PSV-00-000001",
            expiresIn: 3600,
            roles: ["HR", "User"]
        };
        // Convert the tokenData object to a JSON string
        const tokenString = JSON.stringify(tokenData);
        setCookie('user', tokenString, 7);
    };

    const handleButtonClick = async () => {
        // setIsLoading(true);
        try {
            const response = await fetch(`${SERVER_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: "souksavanhso",
                    password: "123456",
                }),
            });

            if (!response.ok) {
                throw new Error('Authentication failed');
            }

            const data = await response.json();
            // console.log('Login successful', data);
            setCookie('user', JSON.stringify(data));
            // navigate('/');
        } catch (error) {
            console.error('Login error', error);
        } finally {
            // setIsLoading(false);
        }
    };

    return (
        <div>
            <Modal
                centered
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center'",
                        flexDirection: "column",
                        // height: '100vh'
                    }}>
                        <Result
                            title={t('Please Login')}
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        // handleClick()
                                        // handleButtonClick()
                                        // window.location.href = 'http://next.phongsavanhgroup.com';
                                        window.location.href = 'https://demo.flexiflows.co';
                                        handleClose()
                                        // window.location.reload();
                                    }}
                                    key="console">
                                    {t('Login')}
                                </Button>
                            }
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
