import React, { useState, useEffect } from 'react'
import { useRoutes, Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import NotificationList from './../pages/notification/NotificationList';
import NotificationDetail from '../pages/notification/NotificationDetail';
import CreateNotification from '../pages/notification/CreateNotification';


export default function Router() {
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            // If the current path is "/", redirect to "/dashboard"
            navigate("/notification-list");
        }
    }, [location.pathname, navigate]);

    return useRoutes([
        {
            element: <PrivateRoute />,
            children: [
                {
                    path: "/notification-list",
                    element: <NotificationList />,
                },
                {
                    path: "/notification-detail/:uid",
                    element: <NotificationDetail />,
                },
                {
                    path: "/create-notification",
                    element: <CreateNotification />,
                },
            ]
        },
        {
            path: '*',
            element: <h1>404</h1>
        },
    ])
}
