import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, HomeOutlined, MenuUnfoldOutlined, NotificationOutlined, DownOutlined } from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { Layout, Menu, theme, Dropdown, Button, Space } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {

    const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || '/management-list');
    const [collapsed, setCollapsed] = useState(false);

    const [language, setLanguage] = useState(localStorage.getItem('locale') || "LA");
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const { token: { colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();

    const _changeLanguage = (locale) => {
        const { pathname, search } = location;
        const newLocation = `${pathname}${search}`;
        // Save the selected locale to localStorage
        localStorage.setItem('locale', locale);
        navigate(newLocation, { state: { locale } });
        i18n.changeLanguage(locale);
        setLanguage(locale);
    };

    useEffect(() => {
        localStorage.getItem('locale')
    }, [language])

    useEffect(() => {
        localStorage.setItem('selectedKey', selectedKey);
    }, [selectedKey]);


    const menu = [
        {
            key: '/home',
            icon: <HomeOutlined style={{ fontSize: 20 }} />,
            label: t('Home'),
        },
        {
            key: '/notification-list',
            icon: <NotificationOutlined style={{ fontSize: 20 }} />,
            label: t('Notification List'),
        },

    ]


    const items = [
        {
            img: "image_url_for_lao", // Add the URL for the image for LAO
            label:
                <div className=' flex' onClick={() => _changeLanguage('LA')}>
                    <img src="/images/Laos.png" alt="Lao flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                    <span className=' font-bold text-[#076638]'>LAO</span>
                </div>,
            key: '0'
        },
        {
            img: "image_url_for_english", // Add the URL for the image for English
            label:
                <div className=' flex' onClick={() => _changeLanguage('EN')}>
                    <img
                        src="/images/english.png"
                        alt="English flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                    <span className=' font-bold text-[#076638]'>ENGLISH</span>
                </div>,
            key: '1'
        },
    ];

    return (
        <Layout >
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: "#111827" }}>

                <div className="" style={{ display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 20 }}>
                    <span className="lg-logo">
                        <img src="/images/logo.svg" style={{ width: "150px" }} onClick={() => {
                            // navigate('/positon-list')
                            // setSelectedKey('/positon-list')
                        }} />
                    </span>
                </div>

                <Menu theme="dark" mode="inline" style={{ background: "#111827" }}
                    onClick={({ key }) => {
                        if (key == "/home") {
                            window.location.href = 'https://demo.flexiflows.co';
                        } else {
                            navigate(key);
                            setSelectedKey(key)
                        }
                    }}
                    defaultSelectedKeys={[selectedKey]}
                    items={menu}
                />

            </Sider>
            <Layout className="site-layout">
                <Header
                    className="d-flex justify-content-between ps-1 pe-5"
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}
                >
                    <div>
                        {React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger",
                                onClick: () => setCollapsed(!collapsed),
                            }
                        )}
                    </div>
                    <div>
                        <Dropdown
                            className=' max-sm:w-[50%]'
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                        >
                            <Button
                                // style={{ backgroundColor: "#FFCC08" }}
                                type=""
                                className='cursor-pointer hover:bg-none lg:ml-8  text-green-900 font-bold'>
                                <Space>
                                    {language === "EN" ?
                                        <img
                                            src="/images/english.png"
                                            alt="English flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                                        :
                                        <img
                                            src="/images/Laos.png"
                                            alt="Lao flag" style={{ marginRight: '8px', width: "35px", height: "20px" }} />
                                    }
                                    {language}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </div>
                </Header>
                <Content
                    style={{
                        margin: "16px 16px",
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        overflowY: "auto",
                    }}
                >
                    <Outlet />
                </Content>
                {/* <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Phongsavanh Group ©2023
                </Footer> */}
            </Layout>
        </Layout>
    );
};
export default MainLayout;
