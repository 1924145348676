import React, { useState, useEffect } from 'react'
import { CloseOutlined, CheckOutlined, CheckCircleOutlined, SyncOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Breadcrumb, Divider, Tag, Skeleton, Result } from 'antd';
import PopupConfirm from '../../components/PopupConfirm';
import Swal from 'sweetalert2';
import { useNotification } from '../../context/NotificationContext';
import useWindowDimensions from '../../constants/useWindowDimensions';
import { SERVER_URL } from '../../api';
import moment from 'moment';
import axios from 'axios';
import { convertNotiStatus } from '../../constants';
import { useTranslation } from 'react-i18next';
const { Meta } = Card;

export default function NotificationDetail() {

    const { t } = useTranslation()
    const { width } = useWindowDimensions();
    const { fetchData } = useNotification()
    const [confirm, setConfirm] = useState(false)
    const [rejectConfirm, setRejectConfirm] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [notiDetail, setNotiDetail] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { uid } = useParams();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    useEffect(() => {
        fetchDataNoti();
    }, []);

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    const fetchDataNoti = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "notifications/list/" + uid}`, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
            });

            setNotiDetail(response?.data?.results);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
        }
    };

    const onCancelNotification = async () => {
        setLoading(true)
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${tokenData?.token}`);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${SERVER_URL + "notifications/list/cancel/" + uid}`, requestOptions)
                .then(result => {
                    Swal.fire({
                        title: t("cancel success"),
                        icon: "success",
                        denyButtonText: false,
                        timer: 2000,
                        imageHeight: 300,
                        timerProgressBar: true,
                        denyButtonText: false,
                        showCancelButton: false, // Hide the "Cancel" button
                        showConfirmButton: false,
                    });
                    fetchDataNoti()
                    fetchData()
                    setCancel(false);
                    setLoading(false)
                    // goBack();
                })
                .catch(error => setLoading(false));
        } catch (error) {
            // Handle errors
            console.error('Error updating data', error);
            setLoading(false)
        }
    };

    const onApprovalNotification = async () => {
        setLoading(true)
        try {
            const data = {
                status: "approved"
            };

            fetch(`${SERVER_URL + "notifications/list/approval/" + uid}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(result => {
                Swal.fire({
                    title: t("approval success"),
                    icon: "success",
                    denyButtonText: false,
                    timer: 2000,
                    imageHeight: 300,
                    timerProgressBar: true,
                    denyButtonText: false,
                    showCancelButton: false, // Hide the "Cancel" button
                    showConfirmButton: false,
                });
                fetchDataNoti()
                fetchData()
                setConfirm(false)
                setLoading(false)
            })
                .catch(error => setLoading(false));
        } catch (error) {
            // console.log('Error: ', error)
            setLoading(false)
        }
    }

    const onRejectNotification = async () => {
        setLoading(true)
        try {
            const data = {
                status: "rejected"
            };

            fetch(`${SERVER_URL + "notifications/list/approval/" + uid}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(result => {
                Swal.fire({
                    title: t("approval success"),
                    icon: "success",
                    denyButtonText: false,
                    timer: 2000,
                    imageHeight: 300,
                    timerProgressBar: true,
                    denyButtonText: false,
                    showCancelButton: false, // Hide the "Cancel" button
                    showConfirmButton: false,
                });
                fetchDataNoti()
                fetchData()
                setRejectConfirm(false)
                setLoading(false)
            })
                .catch(error => setLoading(false));
        } catch (error) {
            // console.log('Error: ', error)
            setLoading(false)
        }
    }

    return (
        <div style={{ padding: width > 700 ? "" : 20 }}>
            <Breadcrumb
                style={{ fontSize: 16 }}
                items={[
                    {
                        title: (
                            <div style={{ cursor: "pointer" }}>
                                <span onClick={() => navigate('/notification-list')}>{t('Back')}</span>
                            </div>
                        ),
                    },
                    {
                        title: t('Notification Detail'),
                    },
                ]}
            />

            <Skeleton
                loading={loading}
                active
                Card
                paragraph={{
                    rows: 13,
                }}
                style={{ marginTop: 20 }}>
                <div style={{
                    width: width > 700 ? "50%" : "100%",
                    display: width > 700 ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: width > 700 ? "" : 10,
                    paddingTop: width > 700 ? "" : 20
                }}>
                    <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        style={{
                            width: '100%',
                            height: 40,
                            display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                        }}
                        onClick={() => setConfirm(true)}>
                        {t('approved')}
                    </Button>

                    <Button
                        // type="primary"
                        style={{
                            marginLeft: 20,
                            width: '100%',
                            height: 40,
                            display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                        }}
                        onClick={() => {
                            setRejectConfirm(true)
                        }}
                        icon={<SyncOutlined spin />}>
                        {t('rejected')}
                    </Button>

                    <Button
                        // type="primary"
                        style={{
                            marginLeft: 20,
                            width: '100%',
                            height: 40,
                            display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                        }}
                        onClick={() => {
                            setCancel(true)

                        }}
                        icon={<CloseOutlined />}>
                        {t('canceled')}
                    </Button>
                    <Result
                        style={{ display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "" : "none" }}
                        status={notiDetail?.status === "canceled" ? "error" : notiDetail?.status === "rejected" ? "warning" : "success"}
                        title={notiDetail?.status === "canceled" ? t("Failed to send notification") : notiDetail?.status === "rejected" ? t("Waiting for approval from the administrator.") : t("Notification of successful approval")}
                        subTitle={
                            <div>
                                <div style={{ display: notiDetail?.status === "rejected" ? "none" : "" }}>  {notiDetail?.status === "canceled" ? t("cancel approval by") : t("approval by")} :  {notiDetail?.name}</div>
                                <div>
                                    {notiDetail?.status === "rejected" ? t("awaiting admin approval") : ""}
                                </div>
                            </div>
                        }
                    />
                </div>
                <Card
                    style={{
                        width: '100%',
                        marginTop: 20
                    }}
                >
                    <div style={{ display: "flex", flexDirection: width > 700 ? "" : "column" }}>
                        <div style={{ width: width > 700 ? "50%" : "100%", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('title')}</span>
                                <span style={{ fontSize: 16 }}>{notiDetail?.msg_title}</span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('description')}</span>
                                <span style={{ fontSize: 16 }}>{notiDetail?.msg_body}</span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('day,month,year')}</span>
                                <span style={{ fontSize: 16 }}>{moment(notiDetail?.schedule_notification).format("DD-MM-YYYY ")}</span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('time')}</span>
                                <span style={{ fontSize: 16 }}>{moment(notiDetail?.schedule_notification).format("HH:mm")}</span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('status')}</span>
                                <span style={{ fontSize: 16 }}>
                                    <Tag
                                        icon={notiDetail?.status === "waiting" ? <ClockCircleOutlined /> : notiDetail?.status === "canceled" ? <CloseCircleOutlined /> : notiDetail?.status === "rejected" ? <SyncOutlined spin /> : <CheckCircleOutlined />}
                                        style={{
                                            height: 30,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            fontSize: 14
                                        }}
                                        color={convertNotiStatus(notiDetail?.status)}>
                                        {notiDetail?.status === "waiting" ?
                                            t("waiting") : notiDetail?.status === "canceled" ? t("canceled") : notiDetail?.status === "rejected" ? t("rejected") : t("approved")}
                                    </Tag>
                                </span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('createdBy')}</span>
                                <span style={{ fontSize: 16 }}>{notiDetail?.name}</span>
                            </div>
                            <Divider />
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 16, width: "50%" }}>{t('CreatedAt')}</span>
                                <span style={{ fontSize: 16 }}>{moment(notiDetail?.created_at).format("DD-MM-YYYY HH:mm:ss")}</span>
                            </div>
                        </div>
                        <div style={{
                            width: width > 700 ? "50%" : "100%",
                            display: width > 700 ? "flex" : "none",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Button
                                type="primary"
                                icon={<CheckOutlined />}
                                style={{
                                    width: '25%',
                                    height: 40,
                                    display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                                }}
                                onClick={() => setConfirm(true)}>
                                {t('approved')}
                            </Button>

                            <Button
                                // type="primary"
                                style={{
                                    marginLeft: 20,
                                    width: '25%',
                                    height: 40,
                                    display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                                }}
                                onClick={() => {
                                    setRejectConfirm(true)
                                }}
                                icon={<SyncOutlined spin />}>
                                {t('rejected')}
                            </Button>

                            <Button
                                // type="primary"
                                style={{
                                    marginLeft: 20,
                                    width: '25%',
                                    height: 40,
                                    display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "none" : ""
                                }}
                                onClick={() => {
                                    setCancel(true)

                                }}
                                icon={<CloseOutlined />}>
                                {t('canceled')}
                            </Button>
                            <Result
                                style={{ display: notiDetail?.status === "approved" || notiDetail?.status === "canceled" || notiDetail?.status === "rejected" ? "" : "none" }}
                                status={notiDetail?.status === "canceled" ? "error" : notiDetail?.status === "rejected" ? "warning" : "success"}
                                title={notiDetail?.status === "canceled" ? t("Failed to send notification") : notiDetail?.status === "rejected" ? t("Waiting for approval from the administrator.") : t("Notification of successful approval")}
                                subTitle={
                                    <div>
                                        <div style={{ display: notiDetail?.status === "rejected" ? "none" : "" }}>  {notiDetail?.status === "canceled" ? t("cancel approval by") : t("approval by")} :  {notiDetail?.name}</div>
                                        <div>
                                            {notiDetail?.status === "rejected" ? t("awaiting admin approval") : ""}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </Card>
            </Skeleton>

            <PopupConfirm
                show={confirm}
                handleClose={() => setConfirm(false)}
                icon={<CheckCircleOutlined style={{ fontSize: 80, color: "green" }} />}
                title={t('Do you want notification of approval?')}
                description={`${t('title')} : ${notiDetail?.msg_title}`}
                onSave={onApprovalNotification}
                loading={loading}
            />
            <PopupConfirm
                show={rejectConfirm}
                handleClose={() => setRejectConfirm(false)}
                icon={<CheckCircleOutlined style={{ fontSize: 80, color: "green" }} />}
                title={t('Do you want notification of rejected?')}
                description={`${t('title')} : ${notiDetail?.msg_title}`}
                onSave={onRejectNotification}
                loading={loading}
            />
            <PopupConfirm
                show={cancel}
                handleClose={() => setCancel(false)}
                icon={<CloseCircleOutlined style={{ fontSize: 80, color: "red" }} />}
                title={t('Do you want notification of canceled?')}
                description={`${t('title')} : ${notiDetail?.msg_title}`}
                onSave={onCancelNotification}
                loading={loading}
            />
        </div>
    )
}
